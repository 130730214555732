<!--
 * @FileDescription: 自定义LineWrapper组件
 * @Author: 朱建波
 * @Date: 2023-11-27
 * @LastEditors: 朱建波 342393950@qq.com
 *
 * @name: ZLineWrapper
 *
 * @Props
 * @property {Number} vertical             是否换行
 * @property {Number} reverse              标签和内容反色
 * @property {Number} labelWidth           标签宽度
 * @property {Number} labelAlign           标签内容水平对齐方式
 * @property {Number} labelVerticalAlign   标签内容垂直对齐方式 默认 start
 * @property {Number} labelColor           标签内容颜色
 * @property {Number} color                value内容颜色
 * @property {Number} cssStyle             自定义样式
 * @property {Number} labelStyle           标签样式
 * @property {Number} valueStyle           value样式
 *
 * @Slots
 * default                                 默认内容
 *
 * @Methods
-->
<template>
  <div :class="['z-line-wrapper', vertical && 'vertical', reverse && 'reverse', labelVerticalAlign && `label-v-${labelVerticalAlign}`]" :style="cssStyle">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    vertical: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    },
    labelWidth: {
      type: String,
      default: '140rem'
    },
    labelAlign: {
      type: String,
      default: ''
    },
    labelVerticalAlign: {
      type: String,
      default: ''
    },
    labelColor: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    cssStyle: {
      type: Object,
      default: () => ({})
    },
    labelStyle: {
      type: Object,
      default: () => ({})
    },
    valueStyle: {
      type: Object,
      default: () => ({})
    }
  },
  provide() {
    return {
      width: this.labelWidth,
      align: this.labelAlign,
      lc: this.labelColor,
      cc: this.color,
      ls: this.labelStyle,
      vs: this.valueStyle
    }
  },
  data() {
    return {
    }
  },
  watch: {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.z-line-wrapper {
  margin: 8rem 0;
  font-size: 16rem;
}
</style>
