<template>
  <div>
    <list-template
      :search-config="searchConfig"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      @onChangePage="handleCurrentChange"
      @onSearch="onSearch"
      @onHandle="handleClick"
      @onChange="onChange"
      @onBatchImport ="handUpload"
      @onExportData ="handDownload"
    ></list-template>
    <Dialog :visible.sync="showImportDialog" title="导入数据表" size="small" buttonAlign="center" submitText="关闭" :showCancelButton="false" :showFooter="true" :closeOnClickModal="false">
      <LineWrapper labelWidth="100rem" labelColor="#222">
        <LineItem label="下载示例">
          <el-button type="primary" size="small" icon="el-icon-download" @click="handleDownFile(0)">点此下载</el-button>
        </LineItem>
        <LineItem label="下载模板">
          <el-button type="primary" size="small" icon="el-icon-download" @click="handleDownFile(1)">点此下载</el-button>
        </LineItem>
        <LineItem label="上传文件">
          <el-button size="small" icon="el-icon-upload2" @click="handleSelectFile">上传</el-button>
        </LineItem>
      </LineWrapper>
    </Dialog>
    <Dialog :visible.sync="showDialog" :title="title" buttonAlign="center" :submitText="submitText" :loading="dialogLoading" :showCancelButton="showCancelButton" :showFooter="true" :closeOnClickModal="false" :beforeClose="onBeforeClose">
      <LineWrapper class="list-box" label-width="85rem">
        <LineItem :label="item.label" :value="item.value" v-for="item in list" :key="item.label"></LineItem>
      </LineWrapper>
      <el-form ref="form" :model="formData" :rules="rules" label-width="100rem">
        <el-form-item label="修改后金额" prop="money_answer_edit">
          <span v-if="!showCancelButton">{{ formData.money_answer_edit }}</span>
          <el-input v-else v-model="formData.money_answer_edit" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
    </Dialog>
  </div>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
import Dialog from '@/components/common/Dialog'
import LineItem from '@/components/common/LineItem';
import LineWrapper from '@/components/common/LineWrapper';
import { zsGet, zsPost, fileDownload, get_school2 } from '@/api'
import { handleChooseFile, changeOption } from '@/utils'
import { validateNumber } from '@/utils/validate'
export default {
  _config:{'route':{'path':'list','meta':{'title':'列表','keepAlive':true}}},
  mixins: [tableListMixin],
  components: { Dialog, LineItem, LineWrapper },
  data() {
    return {
      showImportDialog: false,
      showDialog: false,
      dialogLoading: false,
      title: '',
      submitText: '',
      showCancelButton: false,
      searchConfig: [
        { prop: 'pay_status', tag: 'select', placeholder: '筛选订单状态', options: [] },
        { prop: 'school_id', tag: 'select', placeholder: '筛选就读学校', options: [] },
        { prop: 'entry_school_id', tag: 'select', placeholder: '筛选入账学校', options: [] },
        { prop: 'student_name', placeholder: '搜索学生姓名' }
      ],
      tableConfig: [
        {prop: 'pay_status_name', label: '订单状态'},
        {prop: 'student_name', label: '学生姓名'},
        {prop: 'student_no', label: '学号'},
        {prop: 'school_year', label: '缴费学年'},
        {prop: 'grade_name', label: '就读年级'},
        {prop: 'class_name', label: '行政班'},
        {prop: 'teacher_name', label: '班主任'},
        {prop: 'school', label: '就读校区'},
        {prop: 'entry_school', label: '入账学校'},
        {prop: 'money_answer', label: '应收金额'},
        {prop: 'handle', label: '操作', width: '120rem', handle: true}
      ],
      list: [],
      formData: { money_answer_edit: '', id: '' },
      rules: {
        money_answer_edit: [
          { required: true, validator: (_, value, callback) => validateNumber(value, callback, { min: 0, max: 1000000, equalToMin: false, equalToMax: true }), trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.getConfig()
  },
  methods: {
    getConfig() {
      get_school2().then(res => {
        this.searchConfig[1].options = changeOption({list: res, label: 'school_name', value: 'id'})
        this.searchConfig[2].options = changeOption({list: res, label: 'school_name', value: 'id'})
      })
      zsGet('/recruit-2024/renew/screen').then(res => {
        const { pay_status_arr = [] } = res
        this.searchConfig[0].options = changeOption({list: pay_status_arr, label: 'pay_status_name', value: 'id'})
      })
    },
    getData() {
      this.getDataApi('/zhaosheng/api/recruit-2024/renew/list')
    },
    getDetail() {
      this.dialogLoading = true
      zsGet('/recruit-2024/renew/detail', { id: this.formData.id }).then(res => {
        this.formData.money_answer_edit = res.money_answer
        this.list = [
          { label: '订单状态', value: res.pay_status_name },
          { label: '学生姓名', value: res.student_name },
          { label: '修改前金额', value: res.money_answer_front }
        ]
      }).finally(() => this.dialogLoading = false)
    },
    handleClick(row, handle) {
      this.formData.id = row.id
      const { type } = handle
      if(type === 'edit') {
        this.title = '编辑'
        this.submitText = '提交'
        this.showCancelButton = true
        this.rules.money_answer_edit[0].required = true
        this.list = [
          { label: '订单状态', value: row.pay_status_name },
          { label: '学生姓名', value: row.student_name },
          { label: '修改前金额', value: row.money_answer_front }
        ]
      } else if(type === 'view') {
        this.title = '查看'
        this.submitText = '关闭'
        this.showCancelButton = false
        this.rules.money_answer_edit[0].required = false
        this.getDetail()
      }
      this.showDialog = true
    },
    initForm() {
      this.formData = { money_answer_edit: '', id: '' }
      this.list = []
    },
    onBeforeClose(done, instance) {
      const { type } = instance
      if(!this.showCancelButton || type !== 'submit') {
        done()
        this.initForm()
        return
      }
      this.$refs['form'].validate(valid => {
        if(valid) {
          instance.confirmButtonLoading = true
          zsPost('/recruit-2024/renew/edit', { ...this.formData }).then(() => {
            this.showDialog = false
            this.getData()
            done()
            this.initForm()
            this.$message.success({ message: '操作成功', duration: 3000 })
          }).finally(() => {
            instance.confirmButtonLoading = false
          })
        }
      })
    },
    handUpload() {
      this.showImportDialog = true
    },
    handleSelectFile() {
      handleChooseFile({
        api: '/zhaosheng/api/recruit-2024/renew/template',
        show: true,
        onSuccess: () => {
          this.showImportDialog = false
          this.$message.success({ message: '文件上传成功', duration: 5000 })
          this.getData()
        }
      })
    },
    handleDownFile(type) {
      const config = { name: `高二高三续费${type ? '模板' : '示例'}`, type: 'xlsx' }
      const api = `${process.env.VUE_APP_URL_OSS}finance/${type ? 'template' : 'demo'}.xlsx`
      fileDownload(api, {}, config).then(res => {
        this.showImportDialog = false
      })
    },
    handDownload() {
      fileDownload('/zhaosheng/api/recruit-2024/renew/export', {...this.search}, { name: '高二高三续费', show: true })
    },
  }
}
</script>

<style lang="scss" scoped>
.list-box {
  display: grid;
  gap: 10rem 20rem;
  grid-template-columns: repeat(auto-fill, minmax(200rem, 1fr));
}
</style>
