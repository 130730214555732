import { tableList, get_2024area, get_2024subject } from '@/api'
import { objectIsEmpty, changeOption } from '@/utils'

/**
 * @description 获取页面按钮权限
 */
export const permissionsMixin = {
  computed: {
    /**
     * @description 获取页面按钮权限数据
     * @returns {object[]}
     */
    buttonPermissions() {
      return this.$store.state.buttonPermissions
    }
  },
  methods: {
    /**
     * @description 验证当前类型是否有权限
     * @param {string} type
     * @returns {boolean}
     */
    hasPermissions(type) {
      const item = this.buttonPermissions.find(item => item.type === type)
      return item ? true : false
    }
  }
}


/**
 * @description 列表页数据
 */
export const tableListMixin = {
  created() {
    this.page = 1
    this.getData && this.getData()
  },
  activated() {
    this.getData && this.getData()
  },
  data() {
    return {
      search: {},
      tableData: [],
      loading: false,
      total: 0,
      pageCount: 0,
      pageSize: 20,
      page: 1
    }
  },
  methods: {
    /**
     * @description 列表页数据请求函数
     * @param {string} api
     * @param {object} paramsConfig 除搜索框参数外 额外添加的参数
     * @returns {void}
     */
    getDataApi(api, paramsConfig = {}) {
      if(!api) return this.$message.warning('请传入请求接口!')
      if(this.loading) return
      let params = { page: this.page, ...this.search, ...paramsConfig }
      this.loading = true
      tableList(api, params).then(res => {
        const { list, pageCount, total } = res
        this.total = total
        this.pageCount = pageCount
        this.tableData = list
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     * @description 点击搜索按钮触发函数
     * @param {object} val 搜索框返回的数据
     */
    onSearch(val) {
      this.page = 1
      if (this.onBeforeSearch) {
        this.onBeforeSearch(val)
      } else {
        this.search = val
      }
      this.getData && this.getData()
    },
    /**
     * @description 搜索框数据变化触发函数
     * @param {object} val
     */
    onChange(val) {
      this.search = val
    },
    /**
     * @description 重置触发函数
     */
    onReset() {
      if (this.onBeforeReset) {
        this.onBeforeReset()
      } else {
        this.search = {}
      }
    },
    /**
     * @description 点击分页触发函数
     * @param {number} val
     * @returns {void}
     */
    handleCurrentChange(val) {
      this.page = val
      this.getData && this.getData()
    }
  }
}


/**
 * @description 表单页返回
 */
export const formDataBackMixin = {
  methods: {
    /**
     * @description 表单页点击返回触发函数
     * @param {object} formData 待验证的表单数据
     * @param {string[]} list 排除验证的白名单
     * @returns {void}
     */
    formBack(formData, list = []) {
      const isEmpty = objectIsEmpty(formData, list)
      if(isEmpty) return this.$router.back()
      this.$confirm('页面内容尚未保存, 是否继续返回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.back()
      })
    }
  }
}

/**
 * @description 页面加载数据loading效果
 */
export const pageLoadingMixin = {
  data() {
    return {
      loadingCount : 0,
      loadingRequest: ''
    }
  },
  watch: {
    /**
     * @description 监听请求次数
     * @param {number} val
     */
    loadingCount(val) {
      this.loadingRequest = this.$loading({
        lock: true,
        text: '请求中',
        background: 'rgba(255, 255, 255, 0.7)'
      })
      if(!val) {
        this.loadingRequest.close();
      }
    }
  },
  methods: {
    /**
     * @description 请求开始，增加请求次数
     */
    httpStart() {
      this.loadingCount++
    },
    /**
     * @description 请求结束，减少请求次数
     */
    httpEnd() {
      this.loadingCount--
    }
  }
}

/**
 * @description 获取省市区数据
 */
export const getAreaDataMixin = {
  data() {
    return {
      areaOptions: []
    }
  },
  methods: {
    /**
     * @description 获取省市区
     * @param {string} type 获取数据类型  1：省级，2：市，3：区  默认获取省市两级
     * @param {function} callBack 返回函数
     */
    getAreaData(type = '1,2', callBack = () => {}) {
      get_2024area({ address_type: type, is_level: 1 }).then(res => {
        const list = changeOption({ list: res, label: 'address_name', value: 'address_code', children: 'city_list' })
        this.areaOptions = list
        // 返回数据
        if(callBack && typeof callBack === 'function') callBack(list)
      })
    }
  }
}

/**
 * @description 获取省市区数据
 */
export const getSubjectDataMixin = {
  data() {
    return {
      subjectOptions: []
    }
  },
  methods: {
    /**
     * @description 获取2024科目
     * @param {function} callBack 返回函数
     */
    getSubjectData(callBack = () => {}) {
      get_2024subject().then(res => {
        const list = changeOption({ list: res, label: 'subject_name', value: 'id' })
        this.subjectOptions = list
        // 返回数据
        if(callBack && typeof callBack === 'function') callBack(list)
      })
    }
  }
}

/**
 * @description 删除
 */
export const deleteConfirmMixin = {
  methods: {
    handleDeleteConfirm(config = {}) {
      const { message = '此操作将删除该项, 是否继续?', tips = '提示', type = 'warning', confirmButtonText = '确定', cancelButtonText= '取消', loadingButtonText= '删除中' } = config
      this.$confirm(message, tips, {
        confirmButtonText,
        cancelButtonText,
        type,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = loadingButtonText
            window.setTimeout(() => {
              done()
              instance.confirmButtonText = confirmButtonText
              instance.confirmButtonLoading = false
            }, 300)
          } else {
            done()
          }
        }
      }).then(() => {
        this.$message({ type: 'success', message: `删除成功!` })
      })
    },
  }
}
