<!--
 * @FileDescription: 自定义弹框组件
 * @Author: 朱建波
 * @Date: 2023-10-19
 * @LastEditors: 朱建波 342393950@qq.com
 *
 * @name: ZDialog
 *
 * @Props
 * @property {Boolean} visible             是否显示
 * @property {Boolean} loading             是否显示加载状态
 * @property {String} title                显示标题
 * @property {String} size                 弹窗大小 [small|medium|big|large|full]，默认为 medium
 *  @value small 小
 *  @value medium 默认大小
 *  @value big 中等
 *  @value large 大
 *  @value large 全屏
 * @property {String} customClass          自定义类名
 * @property {String} submitText           确认按钮文字
 * @property {String} cancelText           取消按钮文字
 * @property {String} titleAlign           标题齐方式 [left|center|right]，默认为 left
 * @property {String} buttonAlign          底部按钮对齐方式 [left|center|right]，默认为 right
 *  @value left 左对齐
 *  @value center 居中对齐
 *  @value right 右对齐
 * @property {Boolean} showHeader          = [true|false]   是否显示顶部，默认为 true
 * @property {Boolean} showClose           = [true|false]   是否显示关闭按钮，默认为 true
 * @property {Boolean} showFooter          = [true|false]   是否显示底部，默认为 true
 * @property {Boolean} showSubmitButton    = [true|false]   是否显示确定按钮，默认为 true
 * @property {Boolean} showCancelButton    = [true|false]   是否显示取消按钮，默认为 true
 * @property {Boolean} closeOnClickModal   = [true|false]   点击蒙版是否关闭弹窗，默认为 true
 * @property {Boolean} scroll              = [true|false]   弹窗内容滚动 true 内容滚动 false 整体滚动，默认为 true
 * @property {Function} beforeClose        关闭前触发事件
 * @property {Function} beforeCancel       取消前触发事件
 * @property {Function} beforeSubmit       确定前触发事件
 * @property {any} data                    自定义传入data beforeSubmit beforeCancel beforeClose里返回
 *
 * @Slots
 * default                                 默认内容
 * header                                  自定义标题
 * footer                                  自定义底部
 *
 * @Methods
 * close                                   关闭触发事件
 * submit                                  确定触发事件
 * cancel                                  取消触发事件
-->
<script>
export default {
  name: 'ZDialog',
  props: {
    visible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    showLine: { type: Boolean, default: false },
    title: { type: String, default: '标题' },
    size: { type: String, default: 'medium' },
    width: { type: String, default: '' },
    customClass: { type: String, default: '' },
    submitText: { type: String, default: '确认' },
    cancelText: { type: String, default: '取消' },
    submitType: { type: String, default: 'primary' },
    cancelType: { type: String, default: 'default' },
    titleAlign: { type: String, default: 'left' },
    buttonAlign: { type: String, default: 'right' },
    showHeader: { type: Boolean, default: true },
    showClose: { type: Boolean, default: true },
    showFooter: { type: Boolean, default: true },
    showSubmitButton: { type: Boolean, default: true },
    showCancelButton: { type: Boolean, default: true },
    closeOnClickModal: { type: Boolean, default: true },
    scroll: { type: Boolean, default: true },
    beforeClose: Function,
    beforeCancel: Function,
    beforeSubmit: Function,
    data: { type: [Object, String, Number], default: '' },
  },
  data() {
    return {
      isShow: false,
      instance: {
        type: '',
        confirmButtonLoading: false,
        confirmButtonText: '',
        cancelButtonLoading: false,
        cancelButtonText: ''
      }
    }
  },
  watch: {
    visible: {
      handler(val) {
        this.isShow = val
      },
      immediate: true
    },
  },
  computed: {
    cssStyle(){
      if(this.size === 'full') return ''
      if(this.width) return { width: this.width }
      return ''
    }
  },
  methods: {
    open() {
      this.isShow = true
      this.$emit('update:visible', true)
    },
    close() {
      this.isShow = false
      this.onClose()
    },
    onClose(type = 'close') {
      this.instance = { type: '', confirmButtonLoading: false, confirmButtonText: '', cancelButtonLoading: false, cancelButtonText: '' }
      this.$emit(type)
      if(type !== 'close') this.$emit('close')
      this.$emit('update:visible', false)
    },
    handleBeforeClose(type) {
      this.instance.type = type
      if (this.beforeClose) return this.beforeClose(this.onClose, this.instance, this.data)
      this.onClose(type)
    },
    handleClose() {
      if (this.closeOnClickModal) this.handAction('close')
    },
    handAction(type) {
      if (this.instance.confirmButtonLoading || this.instance.cancelButtonLoading) return
      if(this.beforeCancel && type === 'cancel') return this.beforeCancel(this.onClose, this.instance, this.data)
      if(this.beforeSubmit && type === 'submit') return this.beforeSubmit(this.onClose, this.instance, this.data)
      this.handleBeforeClose(type)
    }
  }
}
</script>

<template>
  <div>
    <transition name="fade-scale">
      <div v-if="isShow" ref="dialog" class="z-dialog-wrapper" :class="[size, customClass]">
        <div class="z-dialog-container" :style="[cssStyle]"  v-loading="loading">
          <header :class="['z-dialog-header', showLine && 'line']" v-if="showHeader">
            <slot name="header"><div :class="['title', titleAlign, showClose ? '' : 'no-close']">{{ title }}</div></slot>
            <div :class="['close', (instance.cancelButtonLoading || instance.confirmButtonLoading) ? 'loading' : '']" @click="handAction('close')" v-if="showClose"></div>
          </header>
          <section class="z-dialog-content" :class="[scroll && 'scroll']">
            <div class="inner"><slot></slot></div>
          </section>
          <footer :class="['z-dialog-footer', buttonAlign]" v-if="showFooter && (showSubmitButton || showCancelButton)">
            <slot name="footer">
              <el-button v-if="showCancelButton" :type="cancelType" :loading="instance.cancelButtonLoading" :disabled="instance.confirmButtonLoading" @click="handAction('cancel')">
                {{ instance.cancelButtonLoading ? (instance.cancelButtonText || '保存中...') : cancelText }}
              </el-button>
              <el-button v-if="showSubmitButton" :type="submitType" :loading="instance.confirmButtonLoading" :disabled="instance.cancelButtonLoading" @click="handAction('submit')">
                {{ instance.confirmButtonLoading ? (instance.confirmButtonText || '保存中...') : submitText }}
              </el-button>
            </slot>
          </footer>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-show="isShow" class="z-dialog-shadow" @click="handleClose"></div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
$gap: 24rem;
$index: 2001;
$color: #1d2088; // #326FFF;
$hoverColor: #598BFF; // #326FFF;
.z-dialog {
  &-wrapper {
    position: fixed;
    z-index: $index;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 100rem 0;
    margin: 0 auto;
    max-width: calc(100vw - 60rem);
    overflow-y: auto;
    display: flex;
    pointer-events: none;
    &.small {
      .z-dialog-container {
        width: 480rem;
      }
    }
    &.big {
      .z-dialog-container {
        width: 1004rem;
      }
    }
    &.large {
      .z-dialog-container {
        width: 1400rem;
      }
    }
    &.full {
      max-width: 100%;
      padding: 0;
      .z-dialog-container {
        width: 100%;
        height: 100%;
      }
    }
    &:not(.full) {
      .z-dialog-content.scroll {
        max-height: 70vh;
      }
    }
    &::-webkit-scrollbar {
      width: 5rem;
      height: 5rem;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 1em;
      background-color: rgba(50, 50, 50, 0.6);
    }
    &::-webkit-scrollbar-track {
      border-radius: 1em;
      background-color: hsla(0, 0%, 58.8%, 0.3);
    }
    &.fade-scale-enter, &.fade-scale-leave-to{
      opacity: 0;
      transform: translateY(-20rem);
    }
    &.fade-scale-enter-to, &.fade-scale-leave{
      opacity: 1;
      transform: translateY(0);
    }
    &.fade-scale-enter-active {
      transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1), transform 0.2s cubic-bezier(0, 0, 0.2, 1);
    }
    &.fade-scale-leave-active {
      transition: opacity 0.2s cubic-bezier(0.4, 0, 1, 1), transform 0.2s cubic-bezier(0.4, 0, 1, 1);
    }
  }
  &-shadow {
    z-index: $index - 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    &.fade-enter, &.fade-leave-to {
      opacity: 0;
    }
    &.fade-enter-to, &.fade-leave {
      opacity: 1;
    }
    &.fade-enter-active {
      transition: all 0.25s cubic-bezier(0, 0, 0.2, 1);
    }
    &.fade-leave-active {
      transition: all 0.25s cubic-bezier(0, 0, 0.2, 1);
    }
  }
  &-container {
    position: relative;
    width: 720rem;
    margin: auto;
    color: #666;
    background: #fff;
    border-radius: 4rem;
    box-shadow: 0 2rem 12rem 0 rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
    pointer-events: all;
  }
  &-header {
    position: relative;
    padding: 20rem 20rem 8rem 24rem;
    border-bottom: solid 1rem #F6F9FF;
    &.line {
      .title {
        position: relative;
        padding-left: 14rem;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 3rem;
          height: 20rem;
          background: #1d2088;
        }
      }
    }
    .title {
      padding-right: 36rem;
      color: $color;
      font-size: 18rem;
      line-height: 25rem;
      font-weight: 500;
      &.no-close.center {
        padding-left: 36rem;
        text-align: center;
      }
      &.right {
        text-align: right;
      }
    }
    .close {
      position: absolute;
      top: 20rem;
      right: 20rem;
      width: 26rem;
      height: 26rem;
      background-size: 70%;
      background-repeat: no-repeat;
      background-position: center;
      flex-shrink: 0;
      transition: transform 0.2s;
    }
    .close {
      cursor: pointer;
      transform: rotate(-45deg);
      &::before, &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #ccc;
      }
      &::before {
        width: 2rem;
        height: 18rem;
      }
      &::after {
        height: 2rem;
        width: 18rem;
      }
      &.loading {
        cursor: not-allowed;
      }
      &:not(.loading):hover {
        &::before, &::after {
          background-color: #1d2088;
        }
      }
    }
  }
  &-footer {
    padding: 10rem 20rem 20rem;
    // border-top: solid 1rem #F6F9FF;
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }
  }
  &-content {
    padding: 24rem;
    color: #606266;
    font-size: 14rem;
    flex: 1;
    &.scroll {
      overflow-y: auto;
    }
    .inner {
      position: relative;
      line-height: 26rem;
      &.c {
        .message {
          padding-left: 30rem;
          padding-right: 12rem;
        }
      }
    }
  }
}
</style>
